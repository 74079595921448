import { defineStore } from 'pinia';
import { ICustomerDetails } from '~/types/CustomerDto';

const useCustomerOverviewStore = defineStore('customerOverviewStore', {
    state: () => ({
        customerOverviewDetails: null as ICustomerDetails | null,
        cancelToken: null as any,
    }),
    getters: {
        getCustomerOverviewDetails: (state) => state.customerOverviewDetails,
    },
    actions: {
        setCustomerOverviewDetails(newValue: ICustomerDetails) {
            this.customerOverviewDetails = newValue;
        },

        clearCustomerOverviewDetails() {
            this.customerOverviewDetails = null;
        },

        fetchCustomerOverviewDetails(thread_id: string) {
            if (this.cancelToken) {
                this.cancelToken.cancel();
            }

            const source = this.$nuxt.$axios.CancelToken.source();
            this.cancelToken = source;

            this.$nuxt.$axios
                .$get(`/api/v1/customer/detail/${thread_id}`, { cancelToken: source.token })
                .then((res: any) => {
                    let energy_advisor = null;

                    if (res.energy_advisor) {
                        energy_advisor = {
                            first_name: res.energy_advisor?.first_name,
                            last_name: res.energy_advisor?.last_name,
                            email: res.energy_advisor?.email,
                        };
                    }
                    this.setCustomerOverviewDetails({
                        average_monthly_electricity_bill: res.average_monthly_electricity_bill,
                        address: res.address,
                        electricity_company: res.electricity_company,
                        roof_age: res.roof_age,
                        coordinates: null,
                        customer_notes: res.customer_notes,
                        can_remove_trees: res.can_remove_trees,
                        energy_advisor,
                    });
                    this.cancelToken = null;
                });
        },
    },
});

export default useCustomerOverviewStore;
