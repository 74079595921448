import { defineStore } from 'pinia';

const useDisplayLoadMoreThreads = defineStore('displayLoadMoreThreads', {
    state: () => ({
        displayLoadMoreThreads: true as boolean,
    }),
    getters: {
        getDisplayLoadMoreThreads: (state) => state.displayLoadMoreThreads,
    },
    actions: {
        unsetDisplayLoadMoreThreads() {
            this.displayLoadMoreThreads = false;
        },

        setDisplayLoadMoreThreads() {
            this.displayLoadMoreThreads = true;
        },
    },
});

export default useDisplayLoadMoreThreads;
