import { defineStore } from 'pinia';
import { ISupplierDetails, ISupplierRep } from '~/types/SupplierDetails';
import useParticipantStore from './participantStore';

const useSupplierOverviewStore = defineStore('supplierOverviewStore', {
    state: () => ({
        supplierDetails: null as ISupplierDetails | null,
        cancelToken: null as any,
        allSupplierReps: [] as ISupplierRep[] | [],
    }),
    getters: {
        getSupplierDetails: (state) => state.supplierDetails,
        getAllSupplierReps: (state) => state.allSupplierReps,
        getSupplierRepsNotParticipants: (state) => {
            const participantStore = useParticipantStore();
            return state.allSupplierReps.filter((rep) => {
                const participant = participantStore.getActiveParticipantByUUID(rep.user_uuid);
                return !participant;
            });
        },
        getSupplierRepsParticipants: (state) => {
            const participantStore = useParticipantStore();
            return state.allSupplierReps.filter((rep) => {
                const participant = participantStore.getActiveParticipantByUUID(rep.user_uuid);
                return !!participant;
            });
        },
    },
    actions: {
        setSupplierDetails(supplierDetails: ISupplierDetails) {
            this.supplierDetails = supplierDetails;
        },

        setSupplierReps(supplierReps: ISupplierRep[]) {
            this.allSupplierReps = supplierReps;
        },

        clearSupplierDetails() {
            this.supplierDetails = null;
        },

        fetchSupplierDetails(thread_id: string) {
            if (this.cancelToken) {
                this.cancelToken.cancel();
            }

            const source = this.$nuxt.$axios.CancelToken.source();
            this.cancelToken = source;

            this.$nuxt.$axios
                .$get(`/api/v1/supplier/detail/${thread_id}`, { cancelToken: source.token })
                .then((res: any) => {
                    this.setSupplierDetails({
                        name: res.name,
                        logo: res.logo,
                        number: res.number,
                        year_estabished: res.year_estabished,
                        supplier_distance: {
                            property_postal_code: res.supplier_distance.property_postal_code,
                            supplier_distance_from_postal: res.supplier_distance.supplier_distance_from_postal,
                        },
                        supplier_url: res.supplier_url,
                        average_rating: res.average_rating,
                        number_of_reviews: res.number_of_reviews,
                    });
                    this.cancelToken = null;
                });
        },

        async fetchSupplierReps(user_uuid: string) {
            if (this.cancelToken) {
                this.cancelToken.cancel();
            }
            const source = this.$nuxt.$axios.CancelToken.source();
            this.cancelToken = source;

            const response = await this.$nuxt.$axios.$get(`/api/v1/supplier/supplier_reps/${user_uuid}`, {
                cancelToken: source.token,
            });
            this.setSupplierReps(response as ISupplierRep[]);
        },
    },
});

export default useSupplierOverviewStore;
