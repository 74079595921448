import { defineStore } from 'pinia';

const useNumberOfUnreadStore = defineStore('numberOfUnreadStore', {
    state: () => ({
        oldNumberOfUnread: 0 as number,
    }),
    getters: {
        getOldNumberOfUnread: (state) => state.oldNumberOfUnread,
    },
    actions: {
        setOldNumberOfUnread(newNumber: number) {
            this.oldNumberOfUnread = newNumber;
        },
    },
});

export default useNumberOfUnreadStore;
