/* eslint-disable prefer-template */
import useFlashTokenStore from '~/store/flashTokenStore';
import useMessageListStore from '~/store/messageListStore';
import useParticipantStore from '~/store/participantStore';
import useSearchThreadsStore from '~/store/searchThreadsStore';
import useThreadStore from '~/store/threadListStore';
import { IThreadItem } from '~/types/threadItem';

export default () => {
    let socket: any;

    const connectSocket = () => {
        const flashTokenStore = useFlashTokenStore();
        flashTokenStore.setAccessTokenToStateFromCookie();

        const socketEndpoint = process.env.WEBSOCKET_ENDPOINT;
        socket = new WebSocket(
            `${socketEndpoint}?Authorization=${encodeURIComponent('Bearer ' + flashTokenStore.getAccessToken)}`,
        );

        socket.onopen = () => {
            console.log('WebSocket connection established');
        };

        socket.onmessage = async (socket_message: any) => {
            const message_aggregation = JSON.parse(socket_message.data);

            const threadStore = useThreadStore();
            const messageListStore = useMessageListStore();

            const activeThread: IThreadItem = threadStore.getActiveThread;
            const { message, thread_metadata } = message_aggregation;

            if (activeThread.threadId === message.thread_id) {
                // We need to reset the number on backend as well, if the thread is active, we scroll down to the latest messages
                // and read them.
                await threadStore.resetNumberOfUnreadMessagesInThread(activeThread.threadId);

                const participantStore = useParticipantStore();
                const sender_data = participantStore.getParticipantByUUID(message.sender_id);
                message.sender_data = sender_data;
                messageListStore.setMessages([...messageListStore.getMessages, message]);
            }

            // Get the thread in the desired format
            const thread = await threadStore.fetchThreadByUUID(thread_metadata.thread_id, true);
            // If thread is already in the list remove it and put it to the top, if it's not in the list, just put to top
            const existingIndex = threadStore.getThreads.findIndex((t) => t.threadId === thread.threadId);
            if (existingIndex !== -1) {
                threadStore.threads.splice(existingIndex, 1);
            }
            // If search is applied do not set thread to top of the list, unless its in the view (matches search)
            const searchThreadsStore = useSearchThreadsStore();
            if (!searchThreadsStore.getSearchApplied) {
                threadStore.setSingleThread(thread);
            } else if (existingIndex !== -1) {
                threadStore.setSingleThread(thread);
            }
        };

        socket.onclose = (event: any) => {
            console.log('WebSocket connection closed', event);
            // Not sure how long the connection lasts, reconnect if needed
            setTimeout(connectSocket, 500); // Reconnect after 0.5 seconds
        };

        socket.onerror = (error: any) => {
            console.error('WebSocket error:', error);
        };
    };

    connectSocket();
};
