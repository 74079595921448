import { defineStore } from 'pinia';
import { IQuoteDetails } from '~/types/QuoteDetails';

const useQuoteDetailStore = defineStore('quoteDetailStore', {
    state: () => ({
        quoteDetails: null as IQuoteDetails | null,
        cancelToken: null as any,
    }),
    getters: {
        getQuoteDetails: (state) => state.quoteDetails,
    },
    actions: {
        setQuoteDetails(quoteDetails: IQuoteDetails) {
            this.quoteDetails = quoteDetails;
        },

        clearQuoteDetails() {
            this.quoteDetails = null;
        },

        fetchQuoteDetails(thread_id: string) {
            if (this.cancelToken) {
                this.cancelToken.cancel();
            }

            const source = this.$nuxt.$axios.CancelToken.source();
            this.cancelToken = source;

            this.$nuxt.$axios
                .$get(`/api/v1/quote/detail/${thread_id}`, { cancelToken: source.token })
                .then((res: any) => {
                    this.setQuoteDetails({
                        quote_url: res.quote_url,
                        total_saving: res.total_saving,
                        net_investment: res.net_investment,
                        price_per_wat: res.price_per_watt,
                        payback_period: res.payback_period,
                        need_met: res.need_met / 100,
                    });
                    this.cancelToken = null;
                });
        },
    },
});

export default useQuoteDetailStore;
