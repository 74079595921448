const MESSAGE_TEMPLATES = () => ({
    templates: {
        request_quote: {
            body: `
                I'm interested in getting an online quote from {supplier_name} on the EnergySage Marketplace!
                I look forward to reviewing your quote and learning more about your company.
                Hope we can work together!
                
                Best,
                {sender_name}
            `,
        },
        request_call: {
            body: `
                I'd like to schedule a call to discuss my quote on EnergySage.
                What's the best phone number to reach you?
                
                The best times for me to talk are:
                
                * 
                
                Best,
                {sender_name}
            `,
            cursor_after: '* ',
        },
        request_visit: {
            body: `
                I’d like to schedule a time for a site visit.
                
                The best times for me are:
                
                * 
                
                Best,
                {sender_name}
            `,
            cursor_after: '* ',
        },
        request_quote_changes: {
            body: `
                I'm interested in your quote, but I'd like to request some changes.  Specifically:
                
                * 
                
                Best,
                {sender_name}
            `,
            cursor_after: '* ',
        },
        request_system_design: {
            body: `
                I'm interested in your quote, but I'd like to see what your proposed system
                might look like on my property.
                
                Could you add a system design image to your quote on EnergySage?
                
                Best,
                {sender_name}
            `,
        },
        request_battery: {
            body: `
                Hi {recipient_name},
                
                Would you please add a battery to my quote?
                I am interested to see how it affects the overall cost and savings within your offer
                and in comparison against the other quotes I've received.
                
                Thanks!
            `,
        },
    },
});

export default MESSAGE_TEMPLATES;
