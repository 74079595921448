import { defineStore } from 'pinia';
import { IMessageParticipant } from '~/types/IMessage';

const useParticipantStore = defineStore('participantStore', {
    state: () => ({
        activeThreadParticipants: {} as Record<string, IMessageParticipant>,
        allThreadParticipants: {} as Record<string, IMessageParticipant>,
    }),
    getters: {
        getActiveParticipants: (state) => state.activeThreadParticipants,
        getParticipantByUUID: (state) => (uuid: string) => state.allThreadParticipants[uuid],
        getActiveParticipantByUUID: (state) => (uuid: string) => state.activeThreadParticipants[uuid],
    },
    actions: {
        setParticipantsToAll(participant: IMessageParticipant) {
            this.allThreadParticipants[participant.user_id] = participant;
        },
        async getParticipantsForThread(threadId: string, activeStatuses: Array<string> = []): Promise<any[]> {
            const q: string = activeStatuses.map((status) => `activeStatuses=${status}`).join('&');
            const resp = await this.$nuxt.$axios.$get(`/api/v1/threads/${threadId}/participants?${q}`);
            return resp;
        },

        async fetchAllParticipantsForThread(threadId: string) {
            // Fetch all participants and populate both all and only active records
            // should be called only once on entering thread
            const activeParticipants: Record<string, IMessageParticipant> = {};
            const participants = await this.getParticipantsForThread(threadId, [
                'ACTIVE',
                'DELETED_BY_LOSING_QUOTE',
                'DELETED_BY_RMQ',
                'DELETED_MANUALLY',
            ]);
            participants.forEach((element: any) => {
                this.setParticipantsToAll({
                    user_id: element.user_id_index,
                    sender_first_name: element.first_name,
                    sender_last_name: element.last_name,
                    sender_avatar_url: element.thumbnail_url,
                });

                if (element.active_status === 'ACTIVE') {
                    activeParticipants[element.user_id_index] = {
                        user_id: element.user_id_index,
                        sender_first_name: element.first_name,
                        sender_last_name: element.last_name,
                        sender_avatar_url: element.thumbnail_url,
                    };
                }
                this.activeThreadParticipants = activeParticipants;
            });
        },
        async fetchActiveParticipantsForThread(threadId: string) {
            // Active thread participants are shown on thread card info, and need new reference to be re-rendered
            const activeParticipants: Record<string, IMessageParticipant> = {};
            const participants = await this.getParticipantsForThread(threadId, ['ACTIVE']);
            participants.forEach((element: any) => {
                activeParticipants[element.user_id_index] = {
                    user_id: element.user_id_index,
                    sender_first_name: element.first_name,
                    sender_last_name: element.last_name,
                    sender_avatar_url: element.thumbnail_url,
                };
            });
            this.activeThreadParticipants = activeParticipants;
        },
        async leaveThread(threadId: string): Promise<string> {
            try {
                await this.$nuxt.$axios.$delete(`/api/v1/threads/${threadId}/participant`);
                return '';
            } catch (error: any) {
                return error.response.data.detail.split(':')[2];
            }
        },
    },
});

export default useParticipantStore;
